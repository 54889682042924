<template>
    <div id="template-content" style="height : 100%; width : 100%; user-select: none;">
        <!-- <div>{{ debug }}</div> -->
        <h1 style="text-align:center;">工资单</h1>
        <p style="text-align:center;">人员编号: {{userID}}&nbsp; &nbsp; 人员姓名: {{name}}</p>
        <p style="text-align:center;">部门: {{mainDepartment}}</p>
        <p style="text-align:center;">{{yearMonth}}薪资单</p>
        <p><br /></p>
        <!-- 注意事项，年终奖，总公司及大部分分公司-->
        <template v-if="(templateId == 2)">
            <p style="text-align:left;"><strong>1. 薪资为公司机密信息，任何人不得打听、议论、泄露本人和他人薪资，违者一律解除劳动合同。</strong></p>
            <p style="text-align:left;"><strong>2. 为了保障员工利益，禁止私自查看他人薪资。</strong></p>
            <p style="text-align:left;"><strong>3. 若您对本工资单中记载的数据有异议，应在工资发放后20日内通过各部门文员与公司人力资源部取得联系，逾期若未提出异议的，公司将不予受理，视作公司已足额支付员工劳动报酬。</strong></p>
        </template>
        <!-- 注意事项，月工资，非年终奖 -->
        <template v-else>
            <p style="text-align:left;"><strong>注意事项:</strong></p>
            <p style="text-align:left;"><strong>1. 薪资为公司机密信息，任何人不得打听、议论、泄露本人和他人薪资，违者一律解除劳动合同。</strong></p>
            <p style="text-align:left;"><strong>2. 为了保障员工利益，禁止私自查看他人薪资。</strong></p>
            <p style="text-align:left;"><strong>3. 工资结算周期为上月26日至本月25日。</strong></p>
            <p style="text-align:left;"><strong>4. 若您对本工资单中记载的数据有异议，应在工资发放后 20 日内通过各部门文员与公司人力资源部取得联系，逾期若未提出异议的，公司将不予受理，视作公司已足额支付员工劳动报酬。</strong></p>
            <p style="text-align:left;"><strong>5. 月工资=固定工资+浮动工资 (绩效考核)</strong></p>
            <p style="text-align:left;"><strong>6. 固定工资: 包含基本工资，周六出勤(单休人员)、全勤奖及工龄工资及各类津贴（如有）</strong></p>
            <p style="text-align:left;"><strong>7. 浮动工资: 绩效考核</strong></p>
        </template>

        <template v-if="(templateId == 0)">
            <p><br /></p>
        
            <p style="text-align:left;"><strong>月工资</strong></p>
            <p>
                <table style="width:100%;" cellpadding="2" cellspacing="0" border="1" bordercolor="#000000">
                    <tbody>
                        <template v-for="row in month_salary_rows" :key="row">
                            <tr>
                                <template v-for="elem in row" :key="elem.key">
                                    <td style="text-align:center;">{{elem.key}}</td>
                                    <td style="text-align:center;">{{elem.value}}</td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </p>
            <p style="text-align:left;"><b>月工资: {{monthly_salary}}</b></p>
        </template>
        
        <p><br /></p>
        
        <p style="text-align:left;"><strong>应得工资加减明细</strong></p>
        <p>
            <table style="width:100%;" cellpadding="2" cellspacing="0" border="1" bordercolor="#000000">
                <tbody>
                    <template v-for="row in accruedRows" :key="row">
                        <tr>
                            <template v-for="elem in row" :key="elem.key">
                                <td style="text-align:center;">{{elem.key}}</td>
                                <td style="text-align:center;">{{elem.value}}</td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
        </p>
        <p style="text-align:left;"><b>应得工资: {{accruedSalary}}</b></p>
        
        <p><br /></p>
        
        <p style="text-align:left;"><strong>实发结算明细</strong></p>
        <p>
            <table style="width:100%;" cellpadding="2" cellspacing="0" border="1" bordercolor="#000000">
                <tbody>
                    <template v-for="row in wuXianYiJinRows" :key="row">
                        <tr>
                            <template v-for="elem in row" :key="elem.key">
                                <td style="text-align:center;">{{elem.key}}</td>
                                <td style="text-align:center;">{{elem.value}}</td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
        </p>
        <p style="text-align:left;"><b>实发合计: {{actualSalary}}</b></p>

        <p style="text-align:right;"><strong><span style="font-size:14px;">{{ firm_title }}</span></strong></p>
        <p><br /></p>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { WuXianYiJin, LevelKeys, YearMonthKeys, DeptKeys, UserIdKeys, AccruedKeys, ActualKeys, Monthly_Salary, is_month_salary_key, getTemplateIdBy } from '../utils/constants'
import { sortByPropertyAscend, numberComparator, replaceAllMatch } from '../utils/helper'
import globalVariables from '../utils/globalVariables'
export default {
    name : 'Template',

    data : function() {
        return {
            // templateId默认为0
            templateId: 0,
            // id : 0,
            name : '',
            userID: '',
            accruedSalary: '',
            actualSalary: '',
            yearMonth: '',
            mainDepartment: '',
            level: '',
            role: '',
            firm_title: '江苏微导纳米科技股份有限公司',
            // DOM
            // 月工资条目
            monthly_salary: '',
            month_salary_rows: [],
            // 应得工资条目
            accruedRows: [],
            // 实发工资条目（五险一金）
            wuXianYiJinRows: [],
            debug: ''
        }
    },

    created() {
        let content = globalVariables.content
        let username = this.$route.query.name
        if (content && username) {
            // 清洗content
            content = replaceAllMatch(content, '{', '')
            content = replaceAllMatch(content, '}', '')
            content = replaceAllMatch(content, '"', '')
            content = replaceAllMatch(content, '\\\\', '')
            let arr = content.split(',')

            // 测试用
            this.debug = content

            let month_salary_elems = []
            let generalElems = []
            let wuXianYiJinElems = []

            // 利用yearMonth 和 role, level 以及 dpt 决定 templateId
            // 年月如果是“年终奖”，会使用不同的模版.
            // 首先要找到yearMonth
            let resolvedYearMonth = '默认当月工资模版'
            let resolvedDept = ''
            let resolvedRole = ''
            let resolvedLevel = ''
            let resolvedSubFirmTitle = ''
            for (let i = 0; i < arr.length; i++) {
                const tuple = arr[i].split(':')
                const key = tuple[0].trim()
                const value = tuple[1].trim()
                if (YearMonthKeys.indexOf(key) != -1) {
                    // 找年月
                    resolvedYearMonth = value
                } else if (DeptKeys.indexOf(key) != -1) {
                    // 找部门
                    resolvedDept = value
                } else if (LevelKeys.indexOf(key) != -1) {
                    // 找职级
                    resolvedLevel = value
                } else if ('岗位' === key) {
                    // 找岗位
                    resolvedRole = value
                } else if ('子公司' === key) {
                    // 是否存在 子公司 字段
                    resolvedSubFirmTitle = value
                }
            }

            // 判断templateId
            const templateId = getTemplateIdBy(resolvedYearMonth)
            this.$nextTick(() => {
                this.yearMonth = resolvedYearMonth
                this.mainDepartment = resolvedDept
                this.role = resolvedRole
                this.level = resolvedLevel
                this.templateId = templateId
                // 特殊情况：先导存在一些跟先导使用统一企业微信的子公司，但是它们的工资单跟先导会有些许不同
                if (resolvedSubFirmTitle !== '') {
                    this.firm_title = resolvedSubFirmTitle
                }
            })

            // 遍历后端传递的信息 
            // [k1,v1]:[k2,v2]:...
            arr.forEach(elem => {
                let tuple = elem.split(':')
                let key = tuple[0]
                let value = tuple[1]

                // 一般类目, 1: 五险一金类目, 2: 特殊类目
                if (UserIdKeys.indexOf(key) != -1) {
                    // 工号
                    this.userID = value
                } else if (AccruedKeys.indexOf(key) != -1) {
                    // 应得工资
                    this.accruedSalary = value
                } else if (ActualKeys.indexOf(key) != -1) {
                    // 实际所得
                    this.actualSalary = value
                } else if ((templateId === 0 || templateId === 5) && Monthly_Salary.indexOf(key) != -1) {
                    // 月工资
                    this.monthly_salary = value
                } else if ((templateId === 0 || templateId === 5) && is_month_salary_key(key)) {
                    // 月工资表条目
                    if (this.isNumber(value.trim())) {
                        // 月工资只关心钱，其他的扔掉，例如空字符串
                        month_salary_elems.push({ 'key': key, 'value': value })
                    }
                } else if (WuXianYiJin.indexOf(key) != -1) {
                    // 实发工资条目
                    // 五险一金类目
                    if (this.isNumber(value.trim())) {
                        // 五险一金类目只关心钱，其他的扔掉
                        wuXianYiJinElems.push({ 'key' : key, 'value' : value })
                    }
                } else {
                    // 一般类目
                    // 应得工资类目
                    if (this.isNumber(value.trim())) {
                        // 数字是 0 的不显示
                        if (value.trim() !== '0' && value.trim() !== '0.0') {
                            // 一般类目只关心钱，其他的扔掉
                            generalElems.push({ 'key' : key, 'value' : value })
                        }
                    }
                }
            })

            // 清洗用户名
            this.name = this.cleanseName(username)
            // 对generalElems进行排序，如果发现了key是以如: 1、开头，则提取序号作为order，否则排到最后
            generalElems.forEach(tuple => {
                let order = generalElems.length
                const keySeq = tuple['key'].split('、')
                if (keySeq.length > 1) {
                    // 有序号
                    order = parseInt(keySeq[0].trim())
                    tuple['key'] = keySeq[1].trim()
                }
                tuple['order'] = order
            })

            // 对month_salary_elems进行排序，如果发现了key是以如: 1、开头，则提取序号作为order，否则排到最后
            month_salary_elems.forEach(tuple => {
                let order = month_salary_elems.length
                const keySeq = tuple['key'].split('、')
                if (keySeq.length > 1) {
                    // 有序号
                    order = parseInt(keySeq[0].trim())
                    tuple['key'] = keySeq[1].trim()
                }
                tuple['order'] = order
            })
            
            this.month_salary_rows = this.splitArrayInto2(sortByPropertyAscend(month_salary_elems, 'order', numberComparator))
            this.accruedRows = this.splitArrayInto2(sortByPropertyAscend(generalElems, 'order', numberComparator))
            this.wuXianYiJinRows = this.splitArrayInto2(wuXianYiJinElems)
        }
    },

    mounted() {
        let self = this
        self.$nextTick(() => {
            // 生成水印
            self.watermark(self.name + self.userID)
            html2canvas(document.querySelector('#template-content')).then(canvas => {
                let oldContent = document.querySelector('#template-content')
                oldContent.replaceWith(canvas)
            })
        })
    },

    methods: {
        // O(n)
        splitArrayInto2(arr) {
            let res = []
            let subGroupCounts = arr.length % 2 == 0 ? arr.length / 2 : (Math.floor(arr.length / 2) + 1)
            for (let subGroupIdx = 0; subGroupIdx < subGroupCounts; subGroupIdx++) {
                res.push([])
            }
            for (let i = 0; i < arr.length; i++) {
                let subGroupOfThisElem = res[Math.floor(i / 2)]
                subGroupOfThisElem.push(arr[i])
            }
            return res
        },

        // 判断一个String是不是Number
        isNumber(val) {
            var regPos = /^\d+(\.\d+)?$/; //非负浮点数
            var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
            if(regPos.test(val) || regNeg.test(val)) {
                return true
            } else {
                return false
            }
        },

        cleanseName(name) {
            name = replaceAllMatch(name, '\\(', '')
            name = replaceAllMatch(name, '\\)', '')
            name = replaceAllMatch(name, '\\（', '')
            name = replaceAllMatch(name, '\\）', '')
            return name
        },

        watermark(mark) {
            var can = document.createElement('canvas')

            var box = document.querySelector('#template-content')
            box.appendChild(can)

            can.width = document.body.offsetWidth
            can.height = document.body.offsetHeight

            can.style.display = 'none'
            can.style.zIndex = '9'

            var ctx = can.getContext('2d')
            ctx.rotate(-25 * Math.PI / 180)
            ctx.font = "800 18px Microsoft JhengHei"
            ctx.fillStyle = "#B8BECC"
            ctx.textAlign = 'center'
            ctx.textBaseline = 'Middle'
            ctx.globalAlpha = 0.2

            for (let i = (document.body.offsetHeight * 0.5) * -1; i < document.body.offsetWidth; i+=160) {
                for (let j = 0; j < (document.body.offsetHeight * 1.5); j+=60) {
                ctx.fillText(mark, i, j)
                }
            }

            box.style.backgroundImage = "url(" + can.toDataURL("image/png") + ")"
        }
    }
}
</script>

<style scoped>

</style>