// 对一个array进行ascend排序, property为property的名字, comparator应该return -1,0,1
export function sortByPropertyAscend(arr, property, comparator) {
	arr.sort(function(o1, o2) {
		let po1 = o1[property]
		let po2 = o2[property]
		return comparator(po1, po2)
	})
	return arr
}


export function numberComparator(num1, num2) {
    let res = true
    if (typeof(num1) != Number && typeof(num2) != Number) {
        res = Number(num1) > Number(num2)
    } else if (typeof(num1) != Number) {
        res = Number(num1) > num2
    } else if (typeof(num2) != Number) {
        res = num1 > Number(num2)
    } else {
        res = num1 > num2
    }
    // 不能return true/false
    if (res) {
        return 1
    } else {
        return -1
    }
}

export function replaceAllMatch(str, s1, s2) {
    return str.replace(new RegExp(s1, "gm"), s2)
}